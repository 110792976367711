import React from "react";
import {
  Box,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Chip,
  IconButton,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  useTheme,
  LinearProgress,
  Avatar,
  AvatarGroup,
} from "@mui/material";
import {
  ThumbUp,
  Comment,
  Share,
  DirectionsCar,
  Memory,
  Camera,
  PhotoCamera,
  Satellite,
  Event,
  CheckCircle,
  Timeline,
  Security,
  Speed,
  Dashboard,
  Science,
  Straighten,
  Sensors,
  AccessTime,
  Agriculture,
} from "@mui/icons-material";

import Header from "../../global_components/parameterized/Header";

const SectionTitle = ({ children }) => (
  <Typography
    variant="h5"
    gutterBottom
    sx={{
      fontWeight: "bold",
      color: "#11192A",
      mb: 2,
    }}
  >
    {children}
  </Typography>
);

const UseCaseCard = ({
  title,
  provider,
  description,
  image,
  icon: Icon,
  stats,
  space,
  category_1,
  category_2,
  category_3,
  likes,
  comments,
  features,
  timeline,
  dashboardLink,
  progress,
  contributors,
}) => {
  const theme = useTheme();

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        minHeight: "1000px",
        boxShadow: 3,
      }}
    >
      <Box sx={{ position: "relative", paddingTop: "56.25%" }}>
        <CardMedia
          component="img"
          image={image}
          alt={title}
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
      </Box>
      <CardContent
        sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}
      >
        <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
          <Icon sx={{ fontSize: 60, color: "#11192A", mr: 2 }} />
          <Box>
            <Typography
              variant="h3"
              color="#11192A"
              component="div"
              gutterBottom
            >
              {title}
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
              {provider}
            </Typography>
          </Box>
        </Box>

        <SectionTitle>Overview</SectionTitle>
        <Typography variant="body1" paragraph>
          {description}
        </Typography>

        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mb: 1, mt: 1 }}>
          <Chip label={space} size="medium" color="primary" />
          <Chip label={category_1} size="medium" variant="outlined" />
          <Chip label={category_2} size="medium" variant="outlined" />
          <Chip label={category_3} size="medium" variant="outlined" />
        </Box>

        <Divider sx={{ my: 3 }} />

        <SectionTitle>Statistics</SectionTitle>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: 2,
            mb: 1,
          }}
        >
          {stats.map((stat, index) => (
            <Typography
              key={index}
              variant="body1"
              sx={{ display: "flex", alignItems: "center" }}
            >
              {React.cloneElement(stat.icon, {
                fontSize: "medium",
                sx: { mr: 1, color: theme.palette.secondary.main },
              })}
              {stat.text}
            </Typography>
          ))}
        </Box>

        <Divider sx={{ my: 3 }} />

        <SectionTitle>Features</SectionTitle>
        <List>
          {features.map((feature, index) => (
            <ListItem key={index}>
              <ListItemIcon>
                <CheckCircle color="success" />
              </ListItemIcon>
              <ListItemText primary={feature} />
            </ListItem>
          ))}
        </List>

        <Divider sx={{ my: 3 }} />

        <SectionTitle>Project Timeline</SectionTitle>
        <Box sx={{ width: "100%", mb: 1, mt: 2 }}>
          <LinearProgress variant="determinate" value={progress} />
          <Typography variant="body2" color="text.secondary" align="right">
            {progress}% Complete
          </Typography>
        </Box>
        <List>
          {timeline.map((item, index) => (
            <ListItem key={index}>
              <ListItemIcon>
                <Timeline color="info" />
              </ListItemIcon>
              <ListItemText primary={item.event} secondary={item.date} />
            </ListItem>
          ))}
        </List>

        <Divider sx={{ my: 3 }} />

        <SectionTitle>Contributors</SectionTitle>
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <AvatarGroup max={4}>
            {contributors.map((contributor, index) => (
              <Avatar
                key={index}
                alt={contributor.name}
                src={contributor.avatar}
              />
            ))}
          </AvatarGroup>
          <Typography variant="body2" sx={{ ml: 2 }}>
            {contributors.length} contributors
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <IconButton size="large">
            <ThumbUp />
          </IconButton>
          <Typography variant="body2" sx={{ mr: 2 }}>
            {likes}
          </Typography>
          <IconButton size="large">
            <Comment />
          </IconButton>
          <Typography variant="body2" sx={{ mr: 2 }}>
            {comments}
          </Typography>
          <IconButton size="large">
            <Share />
          </IconButton>
        </Box>

        <Button
          variant="contained"
          startIcon={<Dashboard />}
          fullWidth
          size="large"
          sx={{
            mt: 2,
            backgroundColor: "#11192A",
          }}
          href={dashboardLink}
        >
          View Dashboard
        </Button>
      </CardContent>
    </Card>
  );
};

const HomePage = () => {
  const useCases = [
    {
      title: "Field Test Vehicle",
      provider:
        "Institute of Product Development, Gottfried Wilhelm Leibniz Universität Hannover",
      description:
        "Our test vehicle integrates technologies to FAIRifies data collection and analysis in automotive environments. This system combines cameras, LiDAR, GPS, and IMU sensors to provide insights into vehicle performance, road conditions, and driving behaviors.",
      image: "../../homepage_assets/passat.jpeg",
      icon: DirectionsCar,
      stats: [
        { icon: <DirectionsCar />, text: "7 Drives" },
        { icon: <Camera />, text: "22 Recordings" },
        { icon: <PhotoCamera />, text: "3580 Images" },
        { icon: <Satellite />, text: "245 LiDAR Scans" },
        //{ icon: <AccessTime />, text: "14 Hours of recordings" },
        //{ icon: <Speed />, text: "xx GB Data Processed" },
      ],
      space: "Automotive",
      category_1: "Field Data",
      category_2: "Dataset Preview",
      category_3: "Digital Twin",
      likes: 21,
      comments: 5,
      features: [
        "360° environmental mapping",
        "Field Data Analysis Framework for Previsualization Before Downloading",
        "AI-powered Object Detection and Classification as a Derived Data Generation from Raw Data (Coming soon!)",
        "AI-Powered Dataset Suggestion (Coming soon!)",
      ],
      timeline: [
        { event: "Project Initiation", date: "September 2020" },
        { event: "Prototype Development", date: "March 2023" },
        { event: "Field Data Collection", date: "June 2023" },
        { event: "Field Database Platform", date: "March 2024" },
      ],
      dashboardLink: "/luh/dashboard",
      progress: 70,
      contributors: [
        { name: "Rayen Hamlaoui", avatar: "https://example.com/avatar4.jpg" },
        {
          name: "Atefeh Gooran-Orimi",
          avatar: "https://example.com/avatar5.jpg",
        },
      ],
    },
    {
      title: "RoBivaL",
      provider:
        "DFKI Robotics Innovation Center, Bremen - HSO Agro-Technicum, Osnabrück",
      description:
        "The RoBivaL project compared robot locomotion concepts from space research and agriculture. Four robots were tested during six experiments addressing different agricultural challenges. Soil conditions were varied regarding moisture and density.",
      image: "../../homepage_assets/robival.png",
      icon: Agriculture,
      stats: [
        { icon: <Science />, text: "6 Experiments" },
        { icon: <Straighten />, text: "30 Parameters" },
        { icon: <CheckCircle />, text: "231 Experiment runs" },
        { icon: <Sensors />, text: "12 Sensor data classes" },
        //{ icon: <AccessTime />, text: "14 Hours of recordings" },
        //{ icon: <Speed />, text: "13.3 GB of sensor data" },
      ],
      space: "Robotics",
      category_1: "Agriculture",
      category_2: "Locomotion",
      category_3: "Field Data",
      likes: 21,
      comments: 5,
      features: [
        "Multimodal sensor data for system and field monitoring",
        "IMU, RTK-GPS, Video camera, Tilt laser scanner, Force logger, Penetrometer",
        "Quantitative documentation of field conditions and systems performance",
        "Specifications of experiments, parameters, systems, and data types",
      ],
      timeline: [
        { event: "Project Start", date: "August 2021" },
        { event: "Field Data Collection", date: "April / June 2023" },
        { event: "Project End", date: "October 2023" },
        { event: "Field Database Platform", date: "March 2024" },
      ],
      dashboardLink: "/dfki/robival/dashboard",
      progress: 100,
      contributors: [
        {
          name: "Backe, Christian (Data manager, DFKI)",
          avatar: "https://example.com/avatar4.jpg",
        },
        {
          name: "Wirkus, Malte (Project leader, DFKI)",
          avatar: "https://example.com/avatar5.jpg",
        },
        {
          name: "Hinck, Stefan (Project member, HSO)",
          avatar: "https://example.com/avatar4.jpg",
        },
        {
          name: "Babel, Jonathan (Project member, DFKI)",
          avatar: "https://example.com/avatar5.jpg",
        },
        {
          name: "Riedel, Vadim (Project member, HSO)",
          avatar: "https://example.com/avatar4.jpg",
        },
        {
          name: "Reichert, Nele (Project member, DFKI)",
          avatar: "https://example.com/avatar5.jpg",
        },
        {
          name: "Kolesnikov, Andrej (Project member, DFKI)",
          avatar: "https://example.com/avatar4.jpg",
        },
        {
          name: "Stark, Tobias (Project member, DFKI)",
          avatar: "https://example.com/avatar5.jpg",
        },
        {
          name: "Hilljegerdes, Jens (Project member, DFKI)",
          avatar: "https://example.com/avatar4.jpg",
        },
        {
          name: "Kücüker, Hilmi Dogu (Project member, DFKI)",
          avatar: "https://example.com/avatar5.jpg",
        },
        {
          name: "Barcic, Emir (Project member, HSO)",
          avatar: "https://example.com/avatar5.jpg",
        },
        {
          name: "Klink, Eduard (Project member, HSO)",
          avatar: "https://example.com/avatar4.jpg",
        },
        {
          name: "Ruckelshausen, Arno (Supervisor, HSO)",
          avatar: "https://example.com/avatar5.jpg",
        },
        {
          name: "Kirchner, Frank (Supervisor, DFKI)",
          avatar: "https://example.com/avatar5.jpg",
        },
      ],
    },
  ];

  return (
    <Box m="20px">
      <Header title="GOLO Use-Cases" />
      <Grid container spacing={4}>
        {useCases.map((useCase, index) => (
          <Grid item xs={12} lg={6} key={index}>
            <UseCaseCard {...useCase} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default HomePage;
