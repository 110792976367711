import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import Dashboard from "./provider/luh/dashboard";
import Topbar from "./global_components/constant/Topbar";
import Sidebar from "./global_components/constant/Sidebar";
import CalendarData from "./provider/luh/calendar";
import Drives from "./provider/luh/drives";
import Recordings from "./provider/luh/recordings";
import GPS from "./provider/luh/gps";
import IMU from "./provider/luh/imu";
import IMAGES from "./provider/luh/images";
import LIDAR from "./provider/luh/lidar";
import TIMESTAMPS from "./provider/luh/timestamps";
import MetricsPage from "./provider/luh/metrics";

import Experiments from "./provider/dfki/robival/experiments";
import DashboardRobival from "./provider/dfki/robival/dashboard";
import Experiment from "./provider/dfki/robival/experiment";
import RunObstacleAvoidance from "./provider/dfki/robival/run/obstacle_avoidance";
import RunRepeatedRollover from "./provider/dfki/robival/run/repeated_rollover";
import RunSillCrossing from "./provider/dfki/robival/run/sill_crossing";
import RunStraightTravel from "./provider/dfki/robival/run/straight_travel";
import RunTensileForce from "./provider/dfki/robival/run/tensile_force";
import RunTurnAround from "./provider/dfki/robival/run/turn_around";

import Acknowledgement from "./global_scenes/acknowledgement";
import Imprint from "./global_scenes/imprint";
import HomePage from "./global_scenes/homepage";

function App() {
  const [theme, colorMode] = useMode();

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <Sidebar />
          <main className="content">
            <Topbar />
            <Routes>
              <Route path="/" element={<HomePage />} />

              <Route path="/luh/dashboard" element={<Dashboard />} />
              <Route path="/luh/drives" element={<Drives />} />
              <Route path="/luh/recordings" element={<Recordings />} />
              <Route path="/luh/gps" element={<GPS />} />
              <Route path="/luh/imu" element={<IMU />} />
              <Route path="/luh/acknowledgement" element={<Acknowledgement />} />
              <Route path="/luh/imprint" element={<Imprint />} />
              <Route path="/luh/images" element={<IMAGES />} />
              <Route path="/luh/lidar" element={<LIDAR />} />
              <Route path="/luh/timestamps" element={<TIMESTAMPS />} />
              <Route path="/luh/calendardata" element={<CalendarData />} />
              <Route path="/luh/metrics" element={<MetricsPage />}>
                <Route path=":driveId/:recordingId" element={<MetricsPage />} />
              </Route>

              <Route path="/dfki/robival/dashboard" element={<DashboardRobival />} />
              <Route path="/dfki/robival/experiments" element={<Experiments />} />
              <Route path="/dfki/robival/experiment/:experiment_key" element={<Experiment />} />
              <Route path="/dfki/robival/run/obstacle_avoidance/:runId" element={<RunObstacleAvoidance />} />
              <Route path="/dfki/robival/run/repeated_rollover/:runId" element={<RunRepeatedRollover />} />
              <Route path="/dfki/robival/run/sill_crossing/:runId" element={<RunSillCrossing />} />
              <Route path="/dfki/robival/run/straight_travel/:runId" element={<RunStraightTravel />} />
              <Route path="/dfki/robival/run/tensile_force/:runId" element={<RunTensileForce />} />
              <Route path="/dfki/robival/run/turn_around/:runId" element={<RunTurnAround />} />
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
