import { useEffect, useState } from "react";

import { Box, Typography, useTheme } from "@mui/material";

import {
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  ScatterChart,
  Scatter,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { tokens } from "../../../../../theme";

import {
  fetchPenetrometerData,
} from "./fetchData";

const scatterColors = [
  "#1f77b4", // Muted blue
  "#2ca02c", // Cooked asparagus green
  "#A6ABBD", // Muted purple
  "#ff7f0e", // Safety orange
  "#d62728", // Brick red
  "#8c564b", // Chestnut brown
  "#e377c2", // Raspberry yogurt pink
  "#7f7f7f", // Middle gray
  "#bcbd22", // Curry yellow-green
  "#17becf", // Blue-teal
];

const PenetrometerBeforeAfterComponent = ( {experimentKey, runId, config} ) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [dataBefore, setDataBefore] = useState([]);
  const [dataAfter, setDataAfter] = useState([]);

  useEffect(() => {
    fetchPenetrometerData("before", experimentKey, runId)
    .then((theDataBefore) => {
      setDataBefore(theDataBefore);
    });
    fetchPenetrometerData("after", experimentKey, runId)
    .then((theDataAfter) => {
      setDataAfter(theDataAfter);
    });
  }, [experimentKey, runId, config]);

  return (
    <>
      {Object.entries({"Before": dataBefore, "After": dataAfter})
      .map(([beforeOrAfter, data]) => Object.values(data)
        .map((rawLocationData) => (
          rawLocationData["depth-m"].map((x, i) => ({
            "location": rawLocationData["location"],
            "depth-m": x,
            "pressure-MPa": rawLocationData["pressure-MPa"][i]
          }))
        ))
        .map((locationData, i) => {
          return (
            <>
              <Box
                p="0 30px"
                display="flex "
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography
                  variant="h5"
                  fontWeight="600"
                  color={colors.grey[100]}
                >
                  {`Penetrometer: ${beforeOrAfter} the run (Location ${i+1})`}
                </Typography>
              </Box>
              <ResponsiveContainer>
                <ScatterChart margin={{ top: 30, right: 30, left: 70, bottom: 5 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="depth-m"
                    type="number"
                    name="Depth"
                    label={{
                      value: 'Depth',
                      position: 'insideBottomRight',
                      offset: -10 }}
                    unit="m"
                  />
                  <YAxis
                    dataKey="pressure-MPa"
                    type="number"
                    name="Pressure"
                    label={{
                      value: 'Pressure',
                      angle: -90,
                      position: 'insideLeft',
                      offset: -10 }}
                    unit="MPa"
                  />
                  <Tooltip cursor={{ strokeDasharray: '3 3' }} />
                  <Legend />
                  <Scatter
                    name={`Location ${i + 1}`}
                    data={locationData}
                    fill={scatterColors[config.scatterColorIndex]} />
                </ScatterChart>
              </ResponsiveContainer>
            </>
          )
        })
      )}
    </>
  );
};

export default PenetrometerBeforeAfterComponent;
