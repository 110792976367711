import React from "react";
// import { useState, useEffect } from "react";
// import axios from "axios";
import { useParams } from 'react-router-dom';
import { Box, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Link } from '@mui/material';

import InfoIcon from '@mui/icons-material/Info';

import { tokens } from "../../../../theme";
import Header from "../../../../global_components/parameterized/Header";

import { experiments } from "./experiments_data";

const Experiment = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { experiment_key } = useParams();
  const data = experiments;
/*
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_DFKI_ROBIVAL_BACKEND_URL}/experiment`
        );
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
*/
  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between">
        <Header title={data[experiment_key].experiment_name} />
        <Box display="flex" justifyContent="space-between" gap="10px">
          <Box display="flex" alignItems="center">
            <Box>
              <img
                alt="ipeg-logo"
                width="240px"
                height="55px"
                src={`../../../assets/IPeG_Logo.png`}
              />
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            <Box>
              <img
                alt="dfki-logo"
                width="230px"
                height="53px"
                src={`../../../assets/DFKI_Logo_2.png`}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        m="20px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.greenAccent[400],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.greenAccent[400],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid
          checkboxSelection
          hideFooter={true}
          ignoreDiacritics={true}
          showCellVerticalBorder={true}
          showColumnVerticalBorder={true}
          rows={data[experiment_key].runs}
          columns={
            Object.values(data[experiment_key].parameters)
            .map((parameter) => ({
              field: `${parameter.parameter_key}`,
              headerName: `${parameter.parameter_name}`,
              align: parameter.datatype === "text" ? "left" : "right",
              description: `
                ${parameter.parameter_name}
                  (${parameter.unit ? `[${parameter.unit}], ` : ""}${parameter.datatype},
                  ${parameter.parameter_role}${parameter.required ? ", required" : ""}).
                ${parameter.parameter_description}
                ${parameter.possible_values ? `Possible values: ${parameter.possible_values.join(", ")}.` : ""}
              `,
              flex: 1,
              headerAlign: "center",
              renderCell: ({
                id,
                field,
                row,
                rowNode,
                colDef,
                cellMode,
                hasFocus,
                tabIndex,
                value,
                formattedValue,
                isEditable,
                api
              }) => {
                return (
                  <Link
                    href={`../../../dfki/robival/run/${experiment_key}/${row[`run__${experiment_key}_id`]}`}
                    target="_blank"
                    rel="noreferrer"
                    underline="none"
                    color="inherit"
                  >
                    {value}
                  </Link>
                )
              },
            }))
          }
          getRowId={(row) => row[`run__${experiment_key}_id`]}
          slots={{
            toolbar: GridToolbar,
          }}
        />
      </Box>
    </Box>
  );
}

export default Experiment;
