import { useEffect, useState } from "react";

import {
  MapContainer,
  TileLayer,
  Polyline,
  Marker,
  Popup,
} from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

import {
  fetchGpsData,
} from "./fetchData";

const startIcon = new L.Icon({
  iconUrl: "/luh/greenmarker.png",
  iconSize: [35, 40],
  iconAnchor: [17, 41],
});

const endIcon = new L.Icon({
  iconUrl: "/luh/redmarker.png",
  iconSize: [35, 40],
  iconAnchor: [17, 41],
});

const MapComponent = ( {experimentKey, runId} ) => {

  const [gpsRawData, setGpsRawData] = useState([]);

  useEffect(() => {
    fetchGpsData('raw', experimentKey, runId)
      .then((data) => {setGpsRawData(data)});
  }, [experimentKey, runId]);

  return (
    <MapContainer
      center={[52.286289812, 8.0210451]}
      zoom={19}
      style={{ height: "620px", width: "620px" }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        maxZoom={23}
      />
      <Polyline
        positions={gpsRawData.map((item) => ([
          item["latitude"],
          item["longitude"],
        ]))}
        color="red" />
    </MapContainer>
  )
}

export default MapComponent;
