import { useEffect, useState } from "react";

import {
  fetchTiltscanSrc,
} from "./fetchData";

const TiltscanComponent = ( {experimentKey, runId, beforeOrAfter} ) => {

  const [tiltscanSrc, setTiltscanSrc] = useState(null);

  useEffect(() => {
    fetchTiltscanSrc(experimentKey, runId, beforeOrAfter)
      .then((url) => setTiltscanSrc(url));
  }, [experimentKey, runId, beforeOrAfter]);

  return (
    <img
      src={tiltscanSrc}
      style={{
        minHeight: "440px",
        objectFit: "contain",
      }}
    />
  )
}

export default TiltscanComponent;
