const runsObstacleAvoidance = [
  {
    "robot": "ARTEMIS",
    "numRuns": 1,
  },
  {
    "robot": "SherpaTT",
    "numRuns": 4,
  },
];

const runsRepeatedRollover = [
  {
    "robot": "ARTEMIS",
    "numRuns": 7,
  },
  {
    "robot": "BoniRob",
    "numRuns": 6,
  },
];

const runsSillCrossing = [
  {
    "robot": "ARTEMIS",
    "numRuns": 13,
  },
  {
    "robot": "BoniRob",
    "numRuns": 10,
  },
  {
    "robot": "Naio Oz",
    "numRuns": 11,
  },
  {
    "robot": "SherpaTT",
    "numRuns": 8,
  },
];

const runsStraightTravel = [
  {
    "robot": "ARTEMIS",
    "numRuns": 21,
  },
  {
    "robot": "BoniRob",
    "numRuns": 19,
  },
  {
    "robot": "Naio Oz",
    "numRuns": 22,
  },
  {
    "robot": "SherpaTT",
    "numRuns": 18,
  },
];

const runsTensileForce = [
  {
    "robot": "ARTEMIS",
    "numRuns": 23,
  },
  {
    "robot": "BoniRob",
    "numRuns": 15,
  },
  {
    "robot": "Naio Oz",
    "numRuns": 18,
  },
  {
    "robot": "SherpaTT",
    "numRuns": 21,
  },
];

const runsTurnAround = [
  {
    "robot": "ARTEMIS",
    "numRuns": 3,
  },
  {
    "robot": "BoniRob",
    "numRuns": 2,
  },
  {
    "robot": "Naio Oz",
    "numRuns": 3,
  },
  {
    "robot": "SherpaTT",
    "numRuns": 6,
  },
];

export {
  runsObstacleAvoidance,
  runsRepeatedRollover,
  runsSillCrossing,
  runsStraightTravel,
  runsTensileForce,
  runsTurnAround,
};
