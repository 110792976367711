export function fetchPhotoSrc(experimentKey: string, runId: number) {
  const dataclassKey = "photo";
  const path = `sensor_files/${experimentKey}/${runId}/${dataclassKey}`;
  return fetch(`${process.env.REACT_APP_DFKI_ROBIVAL_BACKEND_URL}/${path}`)
    .then((response) => response.blob())
    .then((blob) => URL.createObjectURL(blob))
    .catch((error) => {
      console.error("Error:", error);
    });
}

export function fetchTiltscanSrc(
    experimentKey: string, runId: number, beforeOrAfter: string) {
  const dataclassKey = `tiltscan-${beforeOrAfter}-png`;
  const path = `sensor_files/${experimentKey}/${runId}/${dataclassKey}`;
  return fetch(`${process.env.REACT_APP_DFKI_ROBIVAL_BACKEND_URL}/${path}`)
    .then((response) => response.blob())
    .then((blob) => URL.createObjectURL(blob))
    .catch((error) => {
      console.error("Error:", error);
    });
}

export function fetchParameterSpecs() {
  const path = `specification/parameter`;
  return fetch(`${process.env.REACT_APP_DFKI_ROBIVAL_BACKEND_URL}/${path}`)
    .then((response) => response.json())
    .catch((error) => {
      console.error("Error:", error);
    });
}

export function fetchScalarData(experimentKey: string, runId: number) {
  const path = `run/${experimentKey}/${runId}`;
  return fetch(`${process.env.REACT_APP_DFKI_ROBIVAL_BACKEND_URL}/${path}`)
    .then((response) => response.json())
    .catch((error) => {
      console.error("Error:", error);
    });
}

export function fetchGpsData(
    gpsType: string, experimentKey: string, runId: number) {
  const dataclassKey = `gps-${gpsType}`;
  const path = `sensor_files/${experimentKey}/${runId}/${dataclassKey}`;
  return fetch(`${process.env.REACT_APP_DFKI_ROBIVAL_BACKEND_URL}/${path}`)
    .then((response) => response.json())
    .catch((error) => {
      console.error("Error:", error);
    });
}

export function fetchImuData(
    imuType: string, experimentKey: string, runId: number) {
  const dataclassKey = `imu-${imuType}`;
  const path = `sensor_files/${experimentKey}/${runId}/${dataclassKey}`;
  return fetch(`${process.env.REACT_APP_DFKI_ROBIVAL_BACKEND_URL}/${path}`)
    .then((response) => response.json())
    .catch((error) => {
      console.error("Error:", error);
    });
}

export function fetchPenetrometerData(
    penetrometerType: string, experimentKey: string, runId: number) {
  const dataclassKey = `penetrometer-${penetrometerType}`;
  const path = `sensor_files/${experimentKey}/${runId}/${dataclassKey}`;
  return fetch(`${process.env.REACT_APP_DFKI_ROBIVAL_BACKEND_URL}/${path}`)
    .then((response) => response.json())
    .catch((error) => {
      console.error("Error:", error);
    });
}

export function fetchProcessedData(
    experimentKey: string, runId: number, dataclassKey: string) {
  const path = `sensor_processed/${experimentKey}/${runId}/${dataclassKey}`;
  return fetch(`${process.env.REACT_APP_DFKI_ROBIVAL_BACKEND_URL}/${path}`)
    .then((response) => response.json())
    .catch((error) => {
      console.error("Error:", error);
    });
}
