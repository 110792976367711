import { useParams } from "react-router-dom";

import { Box, useTheme, Typography, IconButton } from "@mui/material";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";

import { tokens } from "../../../../../theme";
import Header from "../../../../../global_components/parameterized/Header";

import LineChartComponent from "../components/LineChartComponent";
import MapComponent from "../components/MapComponent";
import PhotoComponent from "../components/PhotoComponent";
import ScalarsChart from "../components/ScalarsChart";
import ScatterChartComponent from "../components/ScatterChartComponent";

const RunObstacleAvoidance = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const experimentKey = "obstacle_avoidance";
  const { runId } = useParams();

  const configAcceleration = {
    xDataKey: "time",
    xDataType: "number",
    xUnit: "s",
    xLabel: { value: 'Runtime', position: 'insideBottomRight', offset: -10 },
    yUnit: "m/ss",
    lines: [
      { dataKey: "x", stroke: "#8884d8", name: "Acc X", },
      { dataKey: "y", stroke: "#82ca9d", name: "Acc Y", },
      { dataKey: "z", stroke: "#A6ABBD", name: "Acc Z", },
    ]
  };

  const configTrajectory = {
    xDataKey: "x",
    xDataType: "number",
    xName: "South - North",
    xLabel: {
      value: 'South - North', position: 'insideBottomRight', offset: -10 },
    xUnit: "m",
    yDataKey: "y",
    yDataType: "number",
    yName: "West - East",
    yLabel: {
      value: 'West - East', angle: -90, position: 'insideLeft', offset: -10 },
    yUnit: "m",
    scatterName: "Robot position",
    scatterColorIndex: 4,
  };

  const configVelocity = {
    xDataKey: "time",
    xDataType: "number",
    xUnit: "s",
    xLabel: { value: 'Runtime', position: 'insideBottomRight', offset: -10 },
    yUnit: "m/s",
    lines: [
      {
        dataKey: "x",
        stroke: "#8884d8",
        name: "V X",
      },
      {
        dataKey: "y",
        stroke: "#82ca9d",
        name: "V Y",
      },
    ]
  };

  const configSpeed = {
    xDataKey: "time",
    xDataType: "number",
    xUnit: "s",
    xLabel: { value: 'Runtime', position: 'insideBottomRight', offset: -10 },
    yUnit: "m/s",
    lines: [
      {
        dataKey: "speed",
        stroke: "#8884d8",
        name: "Speed",
      },
    ]
  };

  const configOrientation = {
    xDataKey: "time",
    xDataType: "number",
    xUnit: "s",
    xLabel: { value: 'Runtime', position: 'insideBottomRight', offset: -10 },
    yUnit: "",
    lines: [
      {
        dataKey: "roll",
        stroke: "#8884d8",
        name: "Roll",
      },
      {
        dataKey: "pitch",
        stroke: "#82ca9d",
        name: "Pitch",
      },
      {
        dataKey: "yaw",
        stroke: "#A6ABBD",
        name: "Yaw",
      },
    ]
  };

  const configMagnetometer = {
    xDataKey: "time",
    xDataType: "number",
    xUnit: "s",
    xLabel: { value: 'Runtime', position: 'insideBottomRight', offset: -10 },
    yUnit: "",
    lines: [
      {
        dataKey: "x",
        stroke: "#8884d8",
        name: "Mag X",
      },
      {
        dataKey: "y",
        stroke: "#82ca9d",
        name: "Mag Y",
      },
      {
        dataKey: "z",
        stroke: "#A6ABBD",
        name: "Mag Z",
      },
    ]
  };

  return (
    <Box m="20px">

      {/* Header */}
      <Box display="flex" justifyContent="space-between">
        <Header
          title="Obstacle avoidance"
          subtitle="Dashboard for a single experiment run"
        />
        <Box display="flex" justifyContent="space-between" gap="10px">
          <Box display="flex" alignItems="center">
            <Box>
              <img
                alt="ipeg-logo"
                width="240px"
                height="55px"
                src={`../../../../assets/IPeG_Logo.png`}
              />
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            <Box>
              <img
                alt="dfki-logo"
                width="230px"
                height="53px"
                src={`../../../../assets/DFKI_Logo_2.png`}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Grid & Charts */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >

        {/* Row 1 */}
        {/* ScalarsChart */}
        <Box
          gridColumn="span 4"
          gridRow="span 4"
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          <ScalarsChart experimentKey={experimentKey} runId={runId} />
        </Box>

        {/* PhotoComponent */}
        <Box
          gridColumn="span 4"
          gridRow="span 4"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <PhotoComponent experimentKey={experimentKey} runId={runId} />
        </Box>

        {/* MapComponent */}
        <Box
          gridColumn="span 4"
          gridRow="span 4"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <MapComponent experimentKey={experimentKey} runId={runId} />
        </Box>


        {/* Row 2 */}
        {/* LineChart: Acceleration */}
        <Box
          gridColumn="span 6"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              variant="h5"
              fontWeight="600"
              color={colors.grey[100]}
            >
              Acceleration
            </Typography>
            <IconButton>
              <DownloadOutlinedIcon
                sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
              />
            </IconButton>
          </Box>
          <Box height="380px" m="0 0 0 0px">
            <LineChartComponent
              experimentKey={experimentKey}
              runId={runId}
              dataclassKey="acceleration"
              config={configAcceleration}
            />
          </Box>
        </Box>

        {/* ScatterChart: Trajectory */}
        <Box
          gridColumn="span 6"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              variant="h5"
              fontWeight="600"
              sx={{ marginBottom: "15px" }}>
                Trajectory
            </Typography>
            <IconButton>
              <DownloadOutlinedIcon
                sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
              />
            </IconButton>
          </Box>
          <Box height="380px" m="0 0 0 -40px">
            <ScatterChartComponent
              experimentKey={experimentKey}
              runId={runId}
              dataclassKey="position"
              config={configTrajectory}
            />
          </Box>
        </Box>

        {/* Row 3 */}
        {/* LineChart: Velocity */}
        <Box
          gridColumn="span 6"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              variant="h5"
              fontWeight="600"
              color={colors.grey[100]}
            >
              Velocity
            </Typography>
            <IconButton>
              <DownloadOutlinedIcon
                sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
              />
            </IconButton>
          </Box>
          <Box height="380px" m="0 0 0 0px">
            <LineChartComponent
              experimentKey={experimentKey}
              runId={runId}
              dataclassKey="velocity"
              config={configVelocity}
            />
          </Box>
        </Box>

        {/* LineChart: Speed */}
        <Box
          gridColumn="span 6"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              variant="h5"
              fontWeight="600"
              color={colors.grey[100]}
            >
              Speed
            </Typography>
            <IconButton>
              <DownloadOutlinedIcon
                sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
              />
            </IconButton>
          </Box>
          <Box height="380px" m="0 0 0 0px">
            <LineChartComponent
              experimentKey={experimentKey}
              runId={runId}
              dataclassKey="speed"
              config={configSpeed}
            />
          </Box>
        </Box>

        {/* Row 4 */}
        {/* LineChart: Orientation */}
        <Box
          gridColumn="span 6"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              variant="h5"
              fontWeight="600"
              color={colors.grey[100]}
            >
              Orientation
            </Typography>
            <IconButton>
              <DownloadOutlinedIcon
                sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
              />
            </IconButton>
          </Box>
          <Box height="380px" m="0 0 0 0px">
            <LineChartComponent
              experimentKey={experimentKey}
              runId={runId}
              dataclassKey="orientation"
              config={configOrientation}
            />
          </Box>
        </Box>

        {/* LineChart: Magnetometer */}
        <Box
          gridColumn="span 6"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              variant="h5"
              fontWeight="600"
              color={colors.grey[100]}
            >
              Magnetometer
            </Typography>
            <IconButton>
              <DownloadOutlinedIcon
                sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
              />
            </IconButton>
          </Box>
          <Box height="380px" m="0 0 0 0px">
            <LineChartComponent
              experimentKey={experimentKey}
              runId={runId}
              dataclassKey="magnetometer"
              config={configMagnetometer}
            />
          </Box>
        </Box>

      </Box>
    </Box>
  );
};

export default RunObstacleAvoidance;
