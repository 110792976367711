import React, { useState, useEffect } from "react";
import axios from "axios";

import { Box, useTheme, Grid } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

import { tokens } from "../../../../theme";
import Header from "../../../../global_components/parameterized/Header";
import experiments_data from "./experiments_data";

import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

const ExperimentCard = ({experiment}) => {
  return (
    <Link
      href={`experiment/${experiment.experiment_key}`}
      underline="none"
      color="inherit"
    >
    <Card
      variant="elevated"
      sx={{ height: "100%", overflowY: 'scroll', }}
    >
      <h2 align="center">{experiment.experiment_name}</h2>
    {(
      experiment.spec_image &&
      <img
        src={`data:image/png;base64,${experiment.spec_image}`}
        width="100%"
      />
    )}
    {(
      experiment.description &&
      <Typography sx={{ fontSize: 22 }}>
        {experiment.description}
      </Typography>
    )}
    </Card>
    </Link>
  );
};

const Experiments = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_DFKI_ROBIVAL_BACKEND_URL}/experiments`
        );
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <Box sx={{ flexGrow: 1, height: "100vh" }}>
      <Grid
        container spacing={1}
        sx={{ height: "100%"}}
      >{
        experiments_data
        .map((experiment) => (
          <Grid
            item xs={4}
            key={experiment.experiment_key}
            sx={{ height: "50%", }}
          >
            <ExperimentCard
              experiment={experiment}
            ></ExperimentCard>
          </Grid>
        ))
      }</Grid>
    </Box>
  );
}

export default Experiments;
