import { useParams } from "react-router-dom";

import { Box, useTheme, Typography, IconButton } from "@mui/material";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";

import { tokens } from "../../../../../theme";
import Header from "../../../../../global_components/parameterized/Header";

import PenetrometerComponent from "../components/PenetrometerComponent";

const RunRepeatedRollover = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const experimentKey = "repeated_rollover";
  const { runId } = useParams();

  const configPenetrometer = {
    xDataKey: "x",
    xDataType: "number",
    xName: "South - North",
    xLabel: {
      value: 'South - North', position: 'insideBottomRight', offset: -10 },
    xUnit: "m",
    yDataKey: "y",
    yDataType: "number",
    yName: "West - East",
    yLabel: {
      value: 'West - East', angle: -90, position: 'insideLeft', offset: -10 },
    yUnit: "m",
    scatterName: "Robot position",
    scatterColorIndex: 4,
  };

  return (
    <Box m="20px">

      {/* Header */}
      <Box display="flex" justifyContent="space-between">
        <Header
          title="Repeated rollover"
          subtitle="Dashboard for a single experiment run"
        />
        <Box display="flex" justifyContent="space-between" gap="10px">
          <Box display="flex" alignItems="center">
            <Box>
              <img
                alt="ipeg-logo"
                width="240px"
                height="55px"
                src={`../../../../assets/IPeG_Logo.png`}
              />
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            <Box>
              <img
                alt="dfki-logo"
                width="230px"
                height="53px"
                src={`../../../../assets/DFKI_Logo_2.png`}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Grid & Charts */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >

        {/* Row 1 */}
        {/* PenetrometerComponent: Overall */}
        <Box
          gridColumn="span 12"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                Penetrometer
              </Typography>
            </Box>
            <Box>
              <IconButton>
                <DownloadOutlinedIcon
                  sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                />
              </IconButton>
            </Box>
          </Box>
          <Box height="380px" m="0 0 0 0px">
            <PenetrometerComponent
              experimentKey={experimentKey}
              runId={runId}
              config={configPenetrometer}
            />
          </Box>
        </Box>

      </Box>
    </Box>
  );
};

export default RunRepeatedRollover;
