import React from "react";
import { useEffect, useState } from "react";

import {
  Box,
  Grid,
  Typography,
} from "@mui/material";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import {
  fetchParameterSpecs,
  fetchScalarData,
} from "./fetchData";

const ScalarsChart = ( {experimentKey, runId} ) => {

  const [scalarData, setScalarData] = useState([]);
  const [ignoreScalarKeys, setIgnoreScalarKeys] = useState([]);
  const [parameterSpecs, setParameterSpecs] = useState([]);

  useEffect(() => {
    fetchScalarData(experimentKey, runId)
      .then((data) => {
        setScalarData(data[0]);
        setIgnoreScalarKeys([
          `run__${experimentKey}_id`,
          "start_datetime_local",
          "start_unix_time",
          "end_datetime_local",
          "end_unix_time",
          "experiment_key",
          "comments",
        ]);
      });
    fetchParameterSpecs()
      .then((data) => setParameterSpecs(data));
  }, [experimentKey, runId]);

  return (
    <Grid>
      <Box sx={{ m: 2 }}>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
          >
            Run: {scalarData[`run__${scalarData["experiment_key"]}_id`]}
          </Typography>
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
          >
            Start: {scalarData["start_datetime_local"]}
          </Typography>
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
          >
            End: {scalarData["end_datetime_local"]}
          </Typography>
        </Box>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "15px 30px",
            fontSize: "1rem",
          }}
        >
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ textAlign: "center" }}>
                    <Typography fontWeight="600">Parameter</Typography>
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <Typography fontWeight="600">Value</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(scalarData)
                  .filter(([key, value]) => ! ignoreScalarKeys.includes(key))
                  .map(([key, value]) => {
                    console.log(key);
                    console.log(parameterSpecs);
                    const name = parameterSpecs[key]["parameter_name"];
                    const unit = parameterSpecs[key]["unit"];
                    return (
                      <TableRow>
                        <TableCell style={{ textAlign: "left" }}>
                          <Typography>{name}</Typography>
                        </TableCell>
                        <TableCell style={{ textAlign: "left" }}>
                          <Typography>{value} {unit}</Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Grid>
  );
};

export default ScalarsChart;
