import { useEffect, useState } from "react";

import {
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  ScatterChart,
  Scatter,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import {
  fetchPenetrometerData,
} from "./fetchData";

const scatterColors = [
  "#1f77b4", // Muted blue
  "#2ca02c", // Cooked asparagus green
  "#A6ABBD", // Muted purple
  "#ff7f0e", // Safety orange
  "#d62728", // Brick red
  "#8c564b", // Chestnut brown
  "#e377c2", // Raspberry yogurt pink
  "#7f7f7f", // Middle gray
  "#bcbd22", // Curry yellow-green
  "#17becf", // Blue-teal
];

const PenetrometerComponent = ( {experimentKey, runId, config} ) => {

  const [data, setData] = useState([]);

  useEffect(() => {
    fetchPenetrometerData("overall", experimentKey, runId)
    .then((theData) => {
      setData(theData);
    });
  }, [experimentKey, runId, config]);

  return (
    <>
      {Object.entries(data)
        .map(([locationKey, rawLocationData]) => {
          return (
            rawLocationData["depth-m"].map((x, i) => {
              return {
                "location": rawLocationData["location"],
                "depth-m": x,
                "pressure-MPa": rawLocationData["pressure-MPa"][i]
              }
            })
          )
        })
        .map((locationData, i) => {
          return (
            <ResponsiveContainer>
              <ScatterChart margin={{ top: 30, right: 30, left: 70, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="depth-m"
                  type="number"
                  name="Depth"
                  label={{
                    value: 'Depth',
                    position: 'insideBottomRight',
                    offset: -10 }}
                  unit="m"
                />
                <YAxis
                  dataKey="pressure-MPa"
                  type="number"
                  name="Pressure"
                  label={{
                    value: 'Pressure',
                    angle: -90,
                    position: 'insideLeft',
                    offset: -10 }}
                  unit="MPa"
                />
                <Tooltip cursor={{ strokeDasharray: '3 3' }} />
                <Legend />
                <Scatter
                  name={`Location ${i + 1}`}
                  data={locationData}
                  fill={scatterColors[config.scatterColorIndex]} />
              </ScatterChart>
            </ResponsiveContainer>
          )
        })
      }
    </>
  );
};

export default PenetrometerComponent;
