import { useEffect, useState } from "react";

import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import {
  fetchProcessedData,
} from "./fetchData";

const LineChartComponent = ( {experimentKey, runId, dataclassKey, config} ) => {

  const [data, setData] = useState([]);
  const [xMax, setXMax] = useState(null);

  useEffect(() => {
    fetchProcessedData(experimentKey, runId, dataclassKey).then((theData) => {
      setData(theData);
      setXMax(Math.max(...theData.map((item) => item[config.xDataKey])));
    });
  }, [experimentKey, runId, dataclassKey, config]);

  return (
    <ResponsiveContainer>
      <LineChart
        data={data}
        margin={{ top: 30, right: 30, left: 70, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey={config.xDataKey}
          type={config.xDataType}
          domain={[0, Math.floor(xMax + 1)]}
          unit={config.xUnit}
          allowDecimals={false}
          label={config.xLabel}
        />
        <YAxis
          unit={config.yUnit}
          angle="0"
        />
        <Tooltip />
        <Legend />
        {config.lines.map((line) => (
          <Line
            type="monotone"
            dataKey={line.dataKey}
            stroke={line.stroke}
            name={line.name}
            dot={false}
            strokeWidth={2}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default LineChartComponent;
