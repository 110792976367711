import React from "react";
import { useNavigate } from "react-router-dom";
import { Select, MenuItem, Button, Collapse, Box } from "@mui/material";

const calculateTimeDifference = (startTime, endTime) => {
  // Parse the time strings into seconds
  const startTimeInSeconds = parseFloat(startTime);
  const endTimeInSeconds = parseFloat(endTime);

  // Calculate the time difference in seconds
  const differenceInSeconds = endTimeInSeconds - startTimeInSeconds;

  // Convert the difference to minutes and seconds
  const minutes = Math.floor(differenceInSeconds / 60);
  const seconds = Math.round(differenceInSeconds % 60);

  return `${minutes} minute${minutes !== 1 ? "s" : ""} ${seconds} second${
    seconds !== 1 ? "s" : ""
  }`;
};

const formatTime = (time) => {
  if (!time) return "";

  // Assuming the time format is HHMMSS.mmm
  const hours = Math.floor(time / 10000);
  const minutes = Math.floor((time % 10000) / 100);
  const seconds = Math.floor(time % 100);
  const milliseconds = Math.floor((time - Math.floor(time)) * 1000);

  const formatTwoDigits = (num) => String(num).padStart(2, "0");

  return `${formatTwoDigits(hours)}:${formatTwoDigits(
    minutes
  )}:${formatTwoDigits(seconds)}.${milliseconds}`;
};

const RecordingsDropdown = ({
  recordings = [],
  drive,
  selectedRecording,
  setSelectedRecording,
}) => {
  const handleChange = (event) => {
    setSelectedRecording(event.target.value);
  };

  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate(`/luh/metrics/${drive.driveId}/${selectedRecording}`);
  };

  if (!drive) {
    return null;
  }

  return (
    <Box>
      <Select
        value={selectedRecording}
        onChange={handleChange}
        displayEmpty
        fullWidth
      >
        <MenuItem value="" disabled>
          Select a recording
        </MenuItem>
        {recordings.map((recording, index) => (
          <MenuItem key={index} value={recording.RecordingID}>
            {`Recording ${recording.RecordingID} recorded at ${formatTime(
              recording.StartTimeUTC
            )} (${calculateTimeDifference(
              recording.StartTimeUTC,
              recording.EndTimeUTC
            )})`}
          </MenuItem>
        ))}
      </Select>
      <Collapse in={!!selectedRecording}>
        <Button
          style={{
            fontSize: 14,
            marginTop: 20,
            marginLeft: "auto",
            marginRight: "auto",
            display: "block",
            backgroundColor: "#11192A",
          }}
          sx={{ textTransform: "none" }}
          variant="contained"
          //color="primary"
          onClick={handleButtonClick}
        >
          Show Recording
        </Button>
      </Collapse>
    </Box>
  );
};

export default RecordingsDropdown;
