import { useEffect, useState } from "react";

import {
  fetchPhotoSrc,
} from "./fetchData";

const PhotoComponent = ( {experimentKey, runId} ) => {

  const [photoSrc, setPhotoSrc] = useState(null);

  useEffect(() => {
    fetchPhotoSrc(experimentKey, runId).then((url) => setPhotoSrc(url));
  }, [experimentKey, runId]);

  return (
    <img
      src={photoSrc}
      style={{
        minHeight: "440px",
        objectFit: "contain",
      }}
    />
  )
}

export default PhotoComponent;
