import { useEffect, useState } from "react";

import {
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  ScatterChart,
  Scatter,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import {
  fetchProcessedData,
} from "./fetchData";

const scatterColors = [
  "#1f77b4", // Muted blue
  "#2ca02c", // Cooked asparagus green
  "#A6ABBD", // Muted purple
  "#ff7f0e", // Safety orange
  "#d62728", // Brick red
  "#8c564b", // Chestnut brown
  "#e377c2", // Raspberry yogurt pink
  "#7f7f7f", // Middle gray
  "#bcbd22", // Curry yellow-green
  "#17becf", // Blue-teal
];

const ScatterChartComponent = ( {experimentKey, runId, dataclassKey, config} ) => {

  const [data, setData] = useState([]);
  const [xMax, setXMax] = useState(null);
  const [yMax, setYMax] = useState(null);

  useEffect(() => {
    fetchProcessedData(experimentKey, runId, dataclassKey).then((theData) => {
      setData(theData);
      setXMax(Math.max(...theData.map((item) => item[config.xDataKey])));
      setYMax(Math.max(...theData.map((item) => item[config.yDataKey])));
    });
  }, [experimentKey, runId, dataclassKey, config]);

  return (
    <ResponsiveContainer>
      <ScatterChart margin={{ top: 30, right: 30, left: 70, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey={config.xDataKey}
          type={config.xDataType}
          name={config.xName}
          label={config.xLabel}
          unit={config.xUnit}
          domain={[0, Math.floor(xMax + 1)]}
        />
        <YAxis
          dataKey={config.yDataKey}
          type={config.yDataType}
          name={config.yName}
          label={config.yLabel}
          unit={config.yUnit}
          domain={[0, Math.floor(yMax + 1)]}
        />
        <Tooltip cursor={{ strokeDasharray: '3 3' }} />
        <Legend />
        <Scatter
          name={config.scatterName}
          data={data}
          fill={scatterColors[config.scatterColorIndex]} />
      </ScatterChart>
    </ResponsiveContainer>
  );
};

export default ScatterChartComponent;
