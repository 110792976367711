import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../../../theme";
import Header from "../../../../global_components/parameterized/Header";
import StatBox from "./StatBox";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ZAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
  ScatterChart,
  Scatter,
} from "recharts";

import experiments_data from "./experiments_data";
import {
  runsObstacleAvoidance,
  runsRepeatedRollover,
  runsSillCrossing,
  runsStraightTravel,
  runsTensileForce,
  runsTurnAround,
} from "./runs_scatter_chart";

// Memory (MB) per sensor (all sensors)
// Time (min) per sensor (only timeseries)
import {
  sensorsMemoryTimeSamplesRuns,
} from "./sensors_memory_time_samples_runs";

const DashboardRobival = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const numRunsObstacleAvoidance = 5;
  const numRunsRepeatedRollover = 13;
  const numRunsSillCrossing = 42;
  const numRunsStraightTravel = 80;
  const numRunsTensileForce = 77;
  const numRunsTurnAround = 14;

  // Runs per soil state
  const runsPerSoilState = [
    {'Soil State': 'Dry Tilled', 'Number of Runs': 24},
    {'Soil State': 'Dry Compacted', 'Number of Runs': 26},
    {'Soil State': 'Moist Tilled', 'Number of Runs': 62},
    {'Soil State': 'Moist Compacted', 'Number of Runs': 75},
    {'Soil State': 'Wet Tilled', 'Number of Runs': 22},
    {'Soil State': 'Wet Compacted', 'Number of Runs': 22},
  ];

  // Runs per robot and experiment
  const scatterColors = [
    "#1f77b4", // Muted blue
    "#2ca02c", // Cooked asparagus green
    "#A6ABBD", // Muted purple
    "#ff7f0e", // Safety orange
    "#d62728", // Brick red
    "#8c564b", // Chestnut brown
    "#e377c2", // Raspberry yogurt pink
    "#7f7f7f", // Middle gray
    "#bcbd22", // Curry yellow-green
    "#17becf", // Blue-teal
  ];

  return (
    <Box m="20px">

      {/* Header */}
      <Box display="flex" justifyContent="space-between">
        <Header
          title="RoBivaL Dashboard"
          subtitle="Welcome to the RoBivaL Dashboard"/>
        <Box display="flex" justifyContent="space-between" gap="10px">
          <Box display="flex" alignItems="center">
            <img
              alt="ipeg-logo"
              width="240px"
              height="55px"
              src={`../../assets/IPeG_Logo.png`}/>
          </Box>
          <Box display="flex" alignItems="center">
            <img
              alt="dfki-logo"
              width="230px"
              height="53px"
              src={`../../assets/DFKI_Logo_2.png`}/>
          </Box>
        </Box>
      </Box>
      {/* END Header */}

      {/* Grid of charts */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px">
        
        {/* StatBox: Obstacle Avoidance */}
        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            icon={
              <img
                src={`data:image/png;base64,${experiments_data.filter((x) => x.experiment_key === "obstacle_avoidance")[0]["spec_image"]}`}
                height="80px"
              />
            }
            title={`Obstacle Avoidance: ${numRunsObstacleAvoidance} runs`}
            subtitle={``}
          />
        </Box>
        {/* END StatBox: Obstacle Avoidance */}

        {/* StatBox: Repeated Rollover */}
        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center">
          <StatBox
            icon={""}
            title={`Repeated Rollover: ${numRunsRepeatedRollover} runs`}
            subtitle={``}
          />
        </Box>
        {/* END StatBox: Repeated Rollover */}

        {/* StatBox: Sill Crossing */}
        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center">
          <StatBox
            icon={
              <img
                src={`data:image/png;base64,${experiments_data.filter((x) => x.experiment_key === "sill_crossing")[0]["spec_image"]}`}
                height="80px"
              />
            }
            title={`Sill Crossing: ${numRunsSillCrossing} runs`}
            subtitle={``}
          />
        </Box>
        {/* END StatBox: Sill Crossing */}

        {/* StatBox: Straight Travel */}
        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center">
          <StatBox
            icon={
              <img
                src={`data:image/png;base64,${experiments_data.filter((x) => x.experiment_key === "straight_travel")[0]["spec_image"]}`}
                height="80px"
              />
            }
            title={`Straight Travel: ${numRunsStraightTravel} runs`}
            subtitle={``}
          />
        </Box>
        {/* END StatBox: Straight Travel */}

        {/* StatBox: Tensile Force */}
        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center">
          <StatBox
            icon={
              <img
                src={`data:image/png;base64,${experiments_data.filter((x) => x.experiment_key === "tensile_force")[0]["spec_image"]}`}
                height="80px"
              />
            }
            title={`Tensile Force: ${numRunsTensileForce} runs`}
            subtitle={``}
          />
        </Box>
        {/* END StatBox: Tensile Force */}

        {/* StatBox: Turn Around */}
        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center">
          <StatBox
            icon={
              <img
                src={`data:image/png;base64,${experiments_data.filter((x) => x.experiment_key === "turn_around")[0]["spec_image"]}`}
                height="80px"
              />
            }
            title={`Turn Around: ${numRunsTurnAround} runs`}
            subtitle={``}
          />
        </Box>
        {/* END StatBox: Turn Around */}

        {/* ScatterChart: Runs per robot and experiment */}
        <Box
          gridColumn="span 4"
          gridRow="span 6"
          backgroundColor={colors.primary[400]}
          padding="30px">
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ marginBottom: "15px" }}>
              Runs per robot and experiment
          </Typography>
          <ResponsiveContainer>
            <ScatterChart margin={{ top: 5, right: 30, left: 50, bottom: 5 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="numRuns"
                type="number"
                name="Runs" />
              <YAxis
                dataKey="robot"
                type="category"
                name="Robot"
              />
              <ZAxis
                range={[120, 121]}
              />
              <Tooltip cursor={{ strokeDasharray: '3 3' }} />
              <Legend />
              <Scatter name="Obstacle Avoidance" data={runsObstacleAvoidance} fill={scatterColors[0]} />
              <Scatter name="Repeated Rollover" data={runsRepeatedRollover} fill={scatterColors[1]} />
              <Scatter name="Sill Crossing" data={runsSillCrossing} fill={scatterColors[2]} />
              <Scatter name="Straight Travel" data={runsStraightTravel} fill={scatterColors[3]} />
              <Scatter name="Tensile Force" data={runsTensileForce} fill={scatterColors[4]} />
              <Scatter name="Turn Around" data={runsTurnAround} fill={scatterColors[5]} />
            </ScatterChart>
          </ResponsiveContainer>
        </Box>
        {/* END ScatterChart: Runs per robot and experiment */}

        {/* BarChart: Runs per soil state */}
        <Box
          gridColumn="span 8"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}>
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center">
            <Typography
              variant="h5"
              fontWeight="600"
              color={colors.grey[100]}>
                Runs per soil state
            </Typography>
          </Box>
          <Box height="250px" m="-20px 0 0 0">
            <ResponsiveContainer>
              <BarChart
                data={runsPerSoilState}
                margin={{ top: 30, right: 30, left: 20, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="Soil State" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="Number of Runs" fill="#4590F9" barSize={50} />
              </BarChart>
            </ResponsiveContainer>
          </Box>
        </Box>
        {/* END BarChart: Runs per soil state */}

        {/* BarChart: Memory (MB) per sensor (all sensors) */}
        <Box
          gridColumn="span 8"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}>
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center">
            <Typography
              variant="h5"
              fontWeight="600"
              color={colors.grey[100]}>
                Memory (MB) per sensor (all sensors)
            </Typography>
          </Box>
          <Box height="250px" m="-20px 0 0 0">
            <ResponsiveContainer>
              <BarChart
                data={sensorsMemoryTimeSamplesRuns}
                margin={{ top: 30, right: 30, left: 20, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="Sensor Name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar
                  dataKey="Memory"
                  fill="#4590F9"
                  barSize={50}
                  name="Memory (MB)"/>
              </BarChart>
            </ResponsiveContainer>
          </Box>
        </Box>
        {/* END BarChart: Memory (MB) per sensor (all sensors) */}

        {/* BarChart: Time (min) per sensor (only timeseries) */}
        <Box
          gridColumn="span 8"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}>
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center">
            <Typography
              variant="h5"
              fontWeight="600"
              color={colors.grey[100]}>
                Time (min) per sensor (only timeseries)
            </Typography>
          </Box>
          <Box height="250px" m="-20px 0 0 0">
            <ResponsiveContainer>
              <BarChart
                data={sensorsMemoryTimeSamplesRuns.filter(
                  (x) => !!x['Time'])}
                margin={{ top: 30, right: 30, left: 20, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="Sensor Name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar
                  dataKey="Time"
                  fill="#4590F9"
                  barSize={50}
                  name="Time (min)"/>
              </BarChart>
            </ResponsiveContainer>
          </Box>
        </Box>
        {/* END BarChart: Time (min) per sensor (only timeseries) */}

      </Box>
      {/* END Grid of charts */}

    </Box>
  );
};

export default DashboardRobival;
