const sensorsMemoryTimeSamplesRuns = [
  {
    "Sensor Key": "imu",
    "Sensor Name": "IMU",
    "Memory": 1569.2,
    "Memory Unit": "MB",
    "Time": 89,
    "Time Unit": "min",
    "Samples": null,
    "Runs": null,
  },
  {
    "Sensor Key": "force",
    "Sensor Name": "Force",
    "Memory": 0.1,
    "Memory Unit": "MB",
    "Time": 18,
    "Time Unit": "min",
    "Samples": null,
    "Runs": null,
  },
  {
    "Sensor Key": "gps",
    "Sensor Name": "GPS",
    "Memory": 89.1,
    "Memory Unit": "MB",
    "Time": 136,
    "Time Unit": "min",
    "Samples": null,
    "Runs": null,
  },
  {
    "Sensor Key": "video",
    "Sensor Name": "Video",
    "Memory": 8723.2,
    "Memory Unit": "MB",
    "Time": 256,
    "Time Unit": "min",
    "Samples": null,
    "Runs": null,
  },
  {
    "Sensor Key": "odometry",
    "Sensor Name": "Odometry",
    "Memory": 101.3,
    "Memory Unit": "MB",
    "Time": 10,
    "Time Unit": "min",
    "Samples": null,
    "Runs": null,
  },
  {
    "Sensor Key": "bogie",
    "Sensor Name": "Bogie",
    "Memory": 224.5,
    "Memory Unit": "MB",
    "Time": 9,
    "Time Unit": "min",
    "Samples": null,
    "Runs": null,
  },
  {
    "Sensor Key": "joystick",
    "Sensor Name": "Joystick",
    "Memory": 3.6,
    "Memory Unit": "MB",
    "Time": 15,
    "Time Unit": "min",
    "Samples": null,
    "Runs": null,
  },
  {
    "Sensor Key": "motion",
    "Sensor Name": "Motion",
    "Memory": 47.2,
    "Memory Unit": "MB",
    "Time": 7,
    "Time Unit": "min",
    "Samples": null,
    "Runs": null,
  },
  {
    "Sensor Key": "laser",
    "Sensor Name": "Laserscan",
    "Memory": 9142.5,
    "Memory Unit": "MB",
    "Time": null,
    "Time Unit": "min",
    "Samples": null,
    "Runs": null,
  },
  {
    "Sensor Key": "penetrometer",
    "Sensor Name": "Penetrometer",
    "Memory": 7.2,
    "Memory Unit": "MB",
    "Time": null,
    "Time Unit": "min",
    "Samples": null,
    "Runs": null,
  },
];

export {
  sensorsMemoryTimeSamplesRuns,
};
